import { PAGE_URLS } from '~/config/page-url'

export const listPromotionHome = [
  {
    src: 'assets/images/home/promotion/bonus.webp',
    title: 'X300%',
    des: 'Cấp vốn đặt cược lần đầu',
    link: PAGE_URLS.PROMOTION
  },
  {
    src: 'assets/images/home/promotion/return.webp',
    title: '1,6%',
    des: 'Hoàn trả cực khủng,\nrút tiền tức thì',
    link: PAGE_URLS.PROMOTION
  },
  {
    src: 'assets/images/home/promotion/888.webp',
    title: '888K',
    des: 'Hoàn tiền vé thua\nđầu tiên mỗi ngày',
    link: PAGE_URLS.PROMOTION
  },
  {
    src: 'assets/images/home/promotion/vip.webp',
    title: 'Trải nghiệm VIP',
    des: 'Lên VIP liền,\nnhận tiền cực đã',
    link: PAGE_URLS.PROMOTION
  }
]
